@import '../bootstrap_overrides/_variables.scss';
@import '../mixins/_custom-mixins.scss';

//TWO COLUMN CONTENT
.pb-statement {
    blockquote {
        color: $red;
        @include fluid-type(40px, 70px);
        font-weight: 600;
        padding: 0;
    }
    .sub-text {
        padding-left: 6rem;
        padding-right: 7rem;
        font-weight: 300;
        float: right;
    }
    &.with-sub {
        blockquote {
            text-align: left;

            @include bpr-media-breakpoint(down, md) {
                text-align: center;
            }
        }
    }
}


// Basic styles
.rounded-pill.anim-target {
  border: none !important;
  box-sizing: border-box;
  
  // Using inset box-shadow instead of border for sizing simplicity
  box-shadow: inset 0 0 0 4px $white;

  // Required, since we're setting absolute on pseudo-elements
  position: relative;
  vertical-align: middle;

  &::before,
  &::after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50rem !important;
  }
}

.rounded-pill.anim-target {
    //transition: color 0.25s;

  &::before,
  &::after {
    // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
    border: 4px solid transparent;
    width: 0%;
    height: 0%;
    border-radius: 0rem !important;
  }

  // This covers the top & right borders (expands right, then down)
  &::before {
    top: 0;
    left: 0;
  }

  // And this the bottom & left borders (expands left, then up)
  &::after {
    bottom: 0;
    right: 0;
  }
  
  &.in-view {
    color: $black;
  }

  // Hover styles
  &.in-view::before,
  &.in-view::after {
    width: 100%;
    height: 100%;
    border-radius: 50rem !important;
  }

  &.in-view::before {
    border-top-color: $black; // Make borders visible
    border-right-color: $black;
    transition:
        width 0.25s ease-out, // Width expands first
        height 0.25s ease-out 0.25s, // And then height
        border-radius 1.5s ease-out 1s; // And then border radius
  }

  &.in-view::after {
    border-bottom-color: $black; // Make borders visible
    border-left-color: $black;
    transition:
      border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
      width 0.25s ease-out 0.5s, // And then exanding width
      height 0.25s ease-out 0.75s, // And finally height
      border-radius 1.5s ease-out 1s; // And then border radius
  }
}